.skills {
  width: 100%;
}

.skills-flex {
  display:flex;
  justify-content: center;
  align-items: center;
}

.skills-left {
  display: flex;
  flex-direction: column;
}

.skills-middle {
  display: flex;
  flex-direction: column;
}

.skills-right {
  display: flex;
  flex-direction: column;
}

.image-wrapper {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 150px;
  height: 75px;
  border: 4px solid #5bc8f0;
}



@media screen and (max-width: 560px) {
  .image-wrapper {
    width: 26vw;
    height: 13vw;
    margin: 3vw;
    border: 3px solid #5bc8f0;
    border-radius: 7px;
  }

  .image-wrapper img {
    width: 6vw;
  }

  .skill-name {
    font-size: 3vw;
  }

  .about-description {
    text-align: center;
  }

}

.skill-name {
  margin: 0;
}
