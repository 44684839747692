.projects {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: white;

}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-header {

  position: relative;
  padding: 0;
  margin: 50px 0;
}

.projects-header-text {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 3.5rem;
  font-weight: 600;
  z-index: 2;
  position: relative;
}

.project-header-underline {
  position: absolute;
  z-index: 1;
  width: 85%;
  height: 1.25em;
  bottom: 5%;
  left: 26%;
  background-color: #5bc8f0;
}
