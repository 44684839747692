.project-left {
  display: flex;
  align-items: center;
  margin: 100px 0 0 0;
}

.project-left-backcard {
  max-width: 800px;
  max-height: 730px;
  min-width: 180px;

  background-color: #580fd6;
  display: flex;
  padding: 7% 0 7% 0;
  position: relative;
  transition: all .15s ease-in-out;
  justify-content: flex-start;
  align-items: center;
}

.project-left-backcard:hover {
  transform: scale(.95);
}

.project-left-image {
  width: 85%;
}

.project-left-details {
  left: -3rem;
  margin-right: -2rem;
  position: relative;
  width: 30%;
}

.project-left-title {
  font-size: 3.5vw;
  margin: 5px 0;
}


.project-left-desc {
  font-size: 1.5vw;
  font-weight: 500;
}

.project-left a {
  text-decoration: none;
}

.project-link {
  font-size: 1.4vw;
  letter-spacing: 2px;
  font-weight: bold;
  display:flex;
  flex-direction: column;
  color: white;
  margin: 15px 0;
  border-bottom: solid 2px #5bc8f0;
  width: fit-content;
  text-align: center;
  padding: 3px 10px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  transition: border-color .35s ease, background .35s ease;
}
/** this is what fills the button behind **/
.project-link:before {
    background-color: #5bc8f0;
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: all .35s ease;
}

.project-link:hover:before {
    top: 0;
}

.project-link:hover {
  color: black;
}

@media screen and (min-width: 1500px){

  .project-left-backcard{
    margin-left: 8%;
  }

  .project-link {
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px){


  .project-left-title {
    font-size: 38px;
  }

  .project-left-desc {
    font-size:18px;
  }

  .project-link {
    font-size: 15px;
  }
}

@media screen and (max-width: 750px) {

  .project-left-title {
    font-size: 22px;
  }

  .project-left-desc {
    font-size: 12px;
  }

  .project-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px){
  .project-left-details {
    background-color: rgba(0, 0, 0, 0.59);
    padding: 5px;
    border-radius: 4px;
    width: 100%;
  }
}

@media screen and (max-width: 400px){

  .project-left-details {
    left: -5rem;
    background-color: rgba(0, 0, 0, 0.59);
    padding: 5px;
  }

  .project-left-title {
    font-size: 15px;
  }

  .project-left-desc {
    font-size: 9px;
  }

  .project-left-links {
    font-size: 10px;
  }


}
