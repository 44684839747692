.about {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-header {
  position: relative;
  padding: 0;
  margin: 50px 0;
}

.about-header-text {
  margin: 0;
  padding: 0;
  z-index: 2;
  color: white;
  font-size: 3.5rem;
  font-weight: 600;
  position: relative;
}

.about-header-underline {
  border-radius: 1px;
  bottom: 5%;
  left: 26%;
  position: absolute;
  background-color: #5bc8f0;
  width: 85%;
  height: 1.25em;
  z-index: 1;
}

.about-image {
  width: 20%;
  min-width: 200px;
  max-width: 600px;
}

.about-description {
  margin: 50px 0;
  width: 45%;
  min-width: 250px;
  max-width: 600px;
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 1.75;
}

@media screen and (max-width: 550px){

  .about-header-text {
    font-size: 2rem;
  }

  .header-underline {
    border-radius: 1px;
    bottom: 5%;
    left: 29%;
    position: absolute;
    background-color: #5bc8f0;
    width: 80%;
    height: 1em;
    z-index: -1;
  }
}
