.footer {
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(40,40,40,1);
}

.footer-scroll-top {
  top: 0;
  width: 35px;
  height: 40px;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #5bc8f0;
  position: absolute;
  transition: top .5s ease-in-out;
}

.chevron-img {
  margin: auto;
  margin-top: 8px;
  display: block;
  width: 23px;
  transform: rotate(-90deg);
}

.footer-scroll-top:hover{
  top: -10px;
}

.github-footer {
  background-color: red;
  width: 100px;
}

.email-icon {
  margin: 40px 10px 0px 10px;
  background-color: white;
  border-radius: 50%;
  transition: all .25s ease-in-out;
}

.email-icon:hover {
  transform: scale(1.2);
}

.octicon.octicon-mark-github {
  margin: 40px 10px 0px 10px;
  background-color: white;
  border-radius: 50%;
  transition: all .25s ease-in-out;
}

.octicon.octicon-mark-github:hover {
  transform: scale(1.2);
}

.footer-copyright {
  color:white;
  font-size: 10px;
}
