
.navbar {
  background-color: rgba(40,40,40,1);
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 999999;
}

.nav-links {
  font-size: 16px;
  letter-spacing: 1px;
}

/* Stops navbar fonts from growing insanely large*/
@media screen and (min-width: 415px) {
  .navbar {
    font-size: 1.25rem;
  }
}

.nav-links {
  display: flex;
  justify-content: center;
  margin: 0;
  list-style: none;
  padding: 12px;
}


.nav-links a {
  text-decoration: none;
  color: white;
  padding: 15px;
  transition: all .25s ease-in-out;
}

@media screen and (max-width: 300px) {
  .nav-links a {
    padding: 8px;
  }
}

.nav-links li {
  transition: all .25s ease-in-out;
}

.nav-links li a:hover {
  color: #5bc8f0;
}

.nav-links li:hover{
  transform: scale(1.1);

}
