.contact {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-header {
  position: relative;
  padding: 0;
  margin: 50px 0;
}

.contact-header-text {
  margin: 0;
  padding: 0;
  z-index: 1;
  color: white;
  font-size: 3.5rem;
  font-weight: 600;
  z-index: 2;
  position: relative;
}

.contact-header-underline {
  position: absolute;
  z-index: 1;
  width: 85%;
  height: 1.25em;
  bottom: 5%;
  left: 26%;
  background-color: #5bc8f0;
}

.contact-details {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-input {
  width: 450px;
  margin: 3px 0;
  border: none;
  padding: 7px;
  background-color: #383838;
  color: white;
  font-family: inherit;
}

.contact-input-red {
  border-left: 2px solid red;
}

.contact-input:focus {
  outline: none;
}
::placeholder {
  font-size: 15px;
  color: #a1a1a1;
  font-family: "Montserrat", sans-serif;
}

textarea {
  color: white;
  margin: 3px 0 10px 0;
  resize: none;
  width: 450px;
  border: none;
  padding: 7px;
  background-color: #383838;
  font-family: "Montserrat", sans-serif;
}

.contact-submit {
  height: 30px;
  text-align: center;
  position: relative;
  z-index: 0;
  margin: 0 0 50px 0;
  color: white;
  width: 100px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #000000;
  border: none;
  border-radius: none;
  border-bottom: 2px solid #5bc8f0;
  transition: border-color 0.35s ease, background 0.35s ease;
}

.contact-submit:before {
  background-color: #5bc8f0;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all 0.35s ease;
}

.contact-submit:hover:before {
  top: 0;
}

.contact-submit:hover {
  cursor: pointer;
  color: black;
}

.contact-info-error {
  display: flex;
  width: 460px;
  justify-content: space-between;
  margin: 0;
}

.error {
  color: white;
  margin: 5px 40px 5px 0;
}

@media screen and (max-width: 480px) {
  .contact-instructions {
    text-align: center;
  }

  .contact-input {
    width: 90vw;
  }

  .contact-info-error {
    display: flex;
    width: 90vw;
    justify-content: space-between;
    margin: 0;
  }
}
