.project-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 100px 0 0 0;
}

.project-right-backcard {
  max-width: 800px;
  max-height: 730px;
  min-width: 180px;
  background-color: #580fd6;
  display: flex;
  padding: 7% 0 7% 0;
  position: relative;
  transition: all .15s ease-in-out;
  justify-content: flex-end;
  align-items: center;
}

.project-right-backcard:hover {
  transform: scale(.95);
}

.project-right-image {
  width: 85%;
}

.project-right-details {
  right: -6rem;
  margin-left: -4rem;
  position: relative;
  width: 30%;
}

.project-right-title {
  font-size: 3.5vw;
  margin: 5px 0;
}


.project-right-desc {
  font-size: 1.5vw;
  font-weight: 500;
}

.project-right a {
  text-decoration: none;
}



@media screen and (min-width: 1500px){

  .project-right-backcard{
    margin-right: 8%;
  }

  .project-link {
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px){


  .project-right-title {
    font-size: 38px;
  }

  .project-right-desc {
    font-size:18px;
  }

  .project-link {
    font-size: 15px;
  }
}

@media screen and (max-width: 750px) {

  .project-right-title {
    font-size: 22px;
  }

  .project-right-desc {
    font-size: 12px;
  }

  .project-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px){
  .project-right-details {
    background-color: rgba(0, 0, 0, 0.59);
    padding: 5px;
    border-radius: 4px;
    width:100%;
  }
}

@media screen and (max-width: 400px){

  .project-right-details {
    background-color: rgba(0, 0, 0, 0.59);
    padding: 5px;
  }

  .project-right-title {
    font-size: 15px;
  }

  .project-right-desc {
    font-size: 9px;
  }

  .project-right-links {
    font-size: 10px;
  }

  .project-right-details{
      margin-left: -4rem;
  }

}
