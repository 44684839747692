.hero-container {
  margin: 0;
  padding: 0;
  position: relative;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
}

.hero-text {
  padding: 0 20px;
}

.hero-btn {
  text-decoration: none;
}

.hero-btn {
  font-size: 1.25rem;
  border: 3px solid white;
  padding: 8px 15px;
  color: #5bc8f0;
  border-radius: 7px;
  transition: all .35s ease-in-out;
}

.hero-btn:hover{
  cursor: pointer;
  transform: translate(0, 5%);
  /*[transition-property] [transition-duration] [transition-timing-function] [transition-delay]*/
  border: 3px solid #5bc8f0;
}
