
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

html {
  scroll-behavior: smooth;
  background-color: black;

 }

body {
  margin: 0;
  font-family: 'Montserrat', serif;
  height: 100%;
  scroll-behavior: smooth;
  overscroll-behavior-x:none;
}

/* CSS Classes for everything in hero section*/


span {
  color: #5bc8f0;
}


#tsparticles{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}



/* CSS Classes for Main Content*/

.main-content {
  background-color: black;
  padding: 0;
  position: relative;
}

#canvas-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* CSS Classes for About in Main Content*/
